import "$styles/index.scss"
import "$styles/syntax-highlighting.css"
import * as Turbo from "@hotwired/turbo"
import "bridgetown-lit-renderer"
//import parsePhoneNumber from "libphonenumber-js"

// Example Shoelace components. Mix 'n' match however you like!
import "@shoelace-style/shoelace/dist/components/button/button.js"
import "@shoelace-style/shoelace/dist/components/icon/icon.js"
import "@shoelace-style/shoelace/dist/components/input/input.js"
import "@shoelace-style/shoelace/dist/components/rating/rating.js"
import "@shoelace-style/shoelace/dist/components/divider/divider.js"
import "@shoelace-style/shoelace/dist/components/card/card.js"
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js"
setBasePath("/shoelace-assets")

// Uncomment the line below to add transition animations when Turbo navigates.
// We recommend adding <meta name="turbo-cache-control" content="no-preview" />
// to your HTML head if you turn on transitions. Use data-turbo-transition="false"
// on your <main> element for pages where you don't want any transition animation.
//
// import "./turbo_transitions.js"

// Import all JavaScript & CSS files from src/_components
// To opt into `.global.css` & `.lit.css` nomenclature, change the `css` extension below to `global.css`.
// Read https://www.bridgetownrb.com/docs/components/lit#sidecar-css-files for documentation.
import components from "$components/**/*.{js,jsx,js.rb,css}"

import Intercom from "@intercom/messenger-js-sdk";

Intercom({
  app_id: 'akx6xge8'
  //user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
  //name: user.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
  //email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
  //created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
});